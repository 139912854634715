<ngx-scrolltop
  [backgroundColor]="'#212121'"
  [symbolColor]="'#fafafa'"
  [size]="40"
  [mode]="'classic'"
  position="right"
  theme="gray">
</ngx-scrolltop>
<button type='button' class='btn btn-outline-primary mr-2 mb-1' [routerLink]="['qr']"><i class='fas fa-qrcode'></i> Scan QR (Camera)</button>
<button type='button' class='btn btn-outline-primary mr-2 mb-1 d-none d-sm-inline-block' [routerLink]="['desktop-qr']"><i class='fas fa-qrcode'></i> Desktop QR Scan</button>
<button type='button' class='btn btn-outline-primary mr-2 mb-1' [routerLink]="['full']"><i class='far fa-list-alt'></i> Name List</button>

<div *ngIf='massData' class='mt-2'>
  <div class='alert alert-danger' *ngIf='massData.mass_checkedin === massData.mass_maxcap'>
    Mass's maximum capacity reached. Check-In DISABLED.
  </div>
  <div class='alert alert-danger' *ngIf='massData.mass_walkin === massData.mass_lastmincap'>
    Mass's walk-in capacity reached. Walk-in registration DISABLED.
  </div>
</div>
<p class='mb-0 mt-2 text-muted'>Search</p>
<div class="input-group mb-3">
  <input type="text" class="form-control form-control-lg" placeholder="Code or Name" aria-describedby="button-addon2" [(ngModel)]="searchString">
  <div class="input-group-append">
    <button class="btn btn-outline-danger" type="button" id="button-addon2" (click)='clearSearchString()'><i class="fas fa-eraser"></i></button>
  </div>
</div>
<!--<input class='form-control form-control-lg' id='tempSearch' type='text' placeholder='Name or Code' [(ngModel)]="filteredStatus">-->

<div class='col-12 col-lg-12 mb-2' *ngIf='registrantList.length === 0 && !isLoading'>
  <div style='text-align: center;'>
    <img [src]="'assets/img/noresfound.gif'" height='80' class='mb-2' alt='' loading='lazy'>
    <h5 class='mb-0 text-muted'>There are no registrants to display.</h5>
    <p class='mb-0 text-muted'>Check back again later.</p>
  </div>
</div>
<app-loading-spinner *ngIf='isLoading'></app-loading-spinner>
<ul class='list-group' *ngIf='massData'>
  <li class='list-group-item list-group-item-action' *ngFor="let registrant of registrantList | filter:searchString: 'reg_details'" [routerLink]="[registrant.reg_id]" style='cursor: pointer'>
    {{ registrant.reg_details }} <span class="badge badge-warning fa-pull-right" *ngIf="registrant.cancelled === '1'">Cancelled</span></li>
</ul>


