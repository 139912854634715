import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Mass} from '@/model/mass.model';
import {Registrant} from '@/model/registrant.model';
import {MassData} from '@/model/mass-data.model';
import {ZoneData} from '@/model/zone-data.model';
import {NameList} from '@/model/name-list.model';
import {RegistrantDbSearched} from '@/model/registrant-db-searched.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url: string = 'https://api.visitationseremban.org/mrs/api/';

  constructor(private http: HttpClient) {
  }

  fetchAllMasses() {
    return this.http.get<Mass[]>(this.url + 'checkin_mass_view_all.php');
  }

  fetchMassRegistrants(mass_id: string) {
    return this.http.get<Registrant[]>(this.url + 'checkin_mass_registrant_view_all.php?massid='+mass_id);
  }

  fetchMassData(mass_id: string){
    return this.http.get<MassData>(this.url + 'checkin_mass_data.php?massid='+mass_id);
  }

  fetchZoneData(mass_id: string){
    return this.http.get<ZoneData[]>(this.url + 'checkin_zone_data.php?massid='+mass_id);
  }

  checkinRegistrant(json: Object){
    return this.http.post<any>(this.url + 'checkin_registrant.php', json);
  }

  fetchNameList(mass_id: string){
    return this.http.get<NameList[]>(this.url + 'checkin_mass_registrant_full.php?massid='+mass_id);
  }

  registerWalkin(json: Object){
    return this.http.post(this.url + 'checkin_register_walkin.php', json);
  }

  checkInEmail(reg_id){
    return this.http.get(this.url + 'email_checkin_confirm.php?reg_id=' + reg_id);
  }

  searchRegistrantData(reg_id){
    return this.http.get<RegistrantDbSearched>(this.url + 'public_registrant.php?reg_id=' + reg_id);
  }
}
