<div id="div_current" class="settings topLeft" style="text-align: center;position:fixed" *ngIf='massData'>
  <button type="button" class="btn btn-outline-dark btn-lg mr-2" (click)='navigateMain()' *ngIf='!goBack'><i class="fas fa-sign-out-alt"></i></button>
  <button type="button" class="btn btn-outline-dark btn-lg mr-2" (click)='navigateBack()' *ngIf='goBack'><i class="fas fa-arrow-circle-left"></i></button>
  <button type="button" id="qrScan" class="btn btn-outline-dark btn-lg mr-2" [routerLink]="['qr']" routerLinkActive='active'><i class="fas fa-qrcode"></i></button>
  <button type="button" id="lmAdd" class="btn btn-outline-dark btn-lg" [routerLink]="['walk-in']" routerLinkActive='active' [disabled]='massData.mass_checkedin === massData.mass_maxcap || massData.mass_walkin === massData.mass_lastmincap'><i class="fas fa-user-plus"></i></button>
</div>
<!--<div id="statusIndicators" class="topRight" style="text-align: center;position:fixed">-->
<!--  <div class="btn-group-vertical" *ngIf='massData'>-->
<!--    <button type="button" class="btn btn-primary btn-md"><p class='mb-0' style='font-size: small'>Attendees</p>{{ massData.mass_checkedin }}</button>-->
<!--    <button type="button" class="btn btn-info btn-md"><p class='mb-0' style='font-size: small'>Walk-In</p>{{ massData.mass_walkin }}</button>-->
<!--    <button type="button" class="btn btn-warning btn-md"><p class='mb-0' style='font-size: small'>Max. Cap.</p>{{ massData.mass_maxcap }}</button>-->
<!--  </div>-->
<!--</div>-->
<div class='container'>
  <div class="row justify-content-md-center">
    <div class='col-3 col-lg-2 d-none d-sm-block'>

    </div>
    <div class='col-12 col-lg-8'>
      <div class='card'>
        <div class='card-body'>
          <p class='mb-1 text-muted' *ngIf='massData'>{{ massData.mass_desc }} - {{ massData.mass_lang }}</p>
          <p class='mb-1 text-muted'>Hello, {{ user.name }}</p>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <div class='col-3 col-lg-2 d-none d-sm-block'>
<!--      <div class="position-fixed">-->
<!--        <div class='card'>-->
<!--          <div class='card-body'>-->
<!--            <p>Blablaaaaaaaaaaaaaa</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</div>

<nav class="navbar fixed-bottom navbar-light justify-content-center" *ngIf='massData'
     style='background-color: rgb(255, 255, 255); border-top: 1px solid rgb(239, 239, 239); box-shadow: rgb(223, 223, 223) -1px -2px 4px; height: 70px'>
  <button type="button" class="btn btn-{{ massData.mass_lastmincap === massData.mass_walkin ? 'danger' : 'outline-dark' }} mr-2">{{ massData.mass_walkin }}/{{ massData.mass_lastmincap }}<p class='mb-0' style='font-size: small'>Walk-Ins</p></button>
  <button type="button" class="btn btn-{{ massData.mass_checkedin === massData.mass_maxcap ? 'danger' : 'outline-dark' }} mr-2">{{ massData.mass_checkedin }}/{{ massData.mass_maxcap }}<p class='mb-0' style='font-size: small'>Attendees</p></button>
  <button type="button" class="btn btn-{{ massData.mass_checkedin === massData.mass_maxcap ? 'danger' : 'outline-dark' }} mr-2">{{ seatsLeft(+massData.mass_maxcap, +massData.mass_checkedin) }}<p class='mb-0' style='font-size: small'>Seats Left</p></button>
</nav>


