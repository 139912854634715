import { Component, OnInit } from '@angular/core';
import {ApiService} from '@services/api.service';
import {NameList} from '@/model/name-list.model';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-mobile-full-list',
  templateUrl: './mobile-full-list.component.html',
  styleUrls: ['./mobile-full-list.component.scss']
})
export class MobileFullListComponent implements OnInit {
  searchString = '';
  nameList: NameList[] = [];
  massid: string;
  searchBy: string = 'reg_details';
  isLoading: boolean = false;
  constructor(private apiService: ApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.parent.params.subscribe((params: Params) =>{
      this.massid = params.massid;
      this.apiService.fetchNameList(this.massid).subscribe(resData =>{
        this.isLoading = false;
        this.nameList = resData;
      }, errorMessage =>{
        this.isLoading = false;
      });
    })
  }

  clearSearchString(){
    this.searchString = '';
  }

}
