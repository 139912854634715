<nav class='navbar fixed-top navbar-light'
     style='background-color: rgb(255, 255, 255); border-bottom: 1px solid rgb(239, 239, 239); box-shadow: rgb(223, 223, 223) 1px 1px 4px; height: 60px' >
  <a class='navbar-brand d-none d-sm-block'>
    <img [src]="'assets/img/COV_Logo_3.png'" height='45' alt='' loading='lazy'>
  </a>
</nav>


<div class='container'>
  <div class='row justify-content-md-center' style='padding-top: 80px'>
    <div class='col col-lg-2'>
    </div>
    <div class='col-md-auto'>
      <h2></h2>
    </div>
    <div class='col col-lg-2'>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
<p class='mb-3 text-muted' style='text-align: center'>Mass Check-In System | Version: 2.0</p>
<p class='mb-3 text-muted' style='text-align: center'>&copy; 2021 Church of The Visitation Seremban. All Rights
  Reserved</p>
