import {Component, OnDestroy, OnInit} from '@angular/core';
import {MobileMassService} from '@pages/mobile/mobile-start/mobile-mass.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Registrant} from '@/model/registrant.model';
import {MassData} from '@/model/mass-data.model';
import {User} from '@/model/user.model';
import {AppService} from '@services/app.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-mobile-list',
  templateUrl: './mobile-list.component.html',
  styleUrls: ['./mobile-list.component.scss']
})
export class MobileListComponent implements OnInit, OnDestroy {
  searchString = '';
  massid: string;
  registrantList: Registrant[] = [];
  massData: MassData;
  private registrantsRefreshTimer: any;
  massDataSubscription: Subscription;
  registrantListSubscription: Subscription;
  isLoading: boolean = false;
  sysMessage: string = '';

  constructor(private mobileMassService: MobileMassService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.subscribe(
      (params: Params) =>{
        this.massid = params.massid;
        this.massDataSubscription = this.mobileMassService.massDataChanged.subscribe(resData =>{
          this.massData = resData;
        })
        this.getMassRegistrantList();
        this.refreshRegistrants();
      })
  }

  getMassRegistrantList(){
    this.registrantListSubscription = this.mobileMassService.fetchMassRegistrants(this.massid).subscribe(resData =>{
      this.registrantList = resData;
      this.isLoading = false;
    }, errorMessage =>{
      this.isLoading = false;
    });
  }

  refreshRegistrants(){
    this.registrantsRefreshTimer = setInterval(() => {
      this.getMassRegistrantList();
    }, 25000);
  }

  ngOnDestroy(): void {
    this.massDataSubscription.unsubscribe();
    this.registrantListSubscription.unsubscribe();
    clearInterval(this.registrantsRefreshTimer);
  }

  clearSearchString(){
    this.searchString = '';
  }

}
